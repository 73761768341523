import { ImageProps } from 'next/image'
import Yield3LogoBigSVG from '@/images/Yield3LogoBig.svg'

export function AppConnectionFallbackContainer({
  layout = 'col',
  imgProps = {
    className: 'sm:w-[420px]',
    src: Yield3LogoBigSVG,
    alt: 'Three Arbinauts',
    priority: true
  },
  children
}: {
  layout?: 'row' | 'col'
  imgProps?: ImageProps
  children: React.ReactNode
}) {
  return (
    <div className="flex flex-col">
      {/*<WalletConnectWarning />*/}

      <div className="my-24 flex items-center justify-center px-8">
        <div
          className={`flex flex-col items-center md:flex-${layout} md:items-${
            layout === 'col' ? 'center' : 'start'
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
